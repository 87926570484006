import api from "@/api/users";

const searchUsers = (context, data) =>
  new Promise((resolve, reject) => {
    api
      .searchUsers(data)
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });

const changeLimits = (context, data) =>
  new Promise((resolve, reject) => {
    api
      .changeLimits(data)
      .then(() => {
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });

export default {
  searchUsers,
  changeLimits
};
