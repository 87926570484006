import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index";
Vue.use(Router);

const tokenGuard = (to, from, next) => {
  if (store.getters["auth/getUserToken"]) {
    if (to.path === "/login") {
      next("/");
    } else {
      next();
    }
  } else if (!to.meta.needPermission) {
    next();
  } else {
    next("/login");
  }
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      // =============================================================================
      // LOGGED IN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("@/layout/LoggedInLayout.vue"),
      children: [
        {
          path: "/",
          name: "Teachers",
          component: () => import("@/views/logged-in-pages/Teachers.vue"),
          meta: {
            needPermission: true
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layout/FullPageLayout.vue"),
      children: [
        {
          path: "/login",
          name: "Login",
          component: () => import("@/views/full-page/Login.vue"),
          meta: {
            needPermission: false
          }
        },
        {
          path: "/error-404",
          name: "page-error-404",
          component: () => import("@/views/full-page/Error404.vue"),
          meta: {
            needPermission: false
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/error-404"
    }
  ]
});

router.beforeEach(tokenGuard);

export default router;
