import Vue from "vue";
import VueI18n from "vue-i18n";

import hu_HU from "./languages/hu_HU.json";

Vue.use(VueI18n);

const locale = "hu_HU";
const messages = {
  hu_HU
};

const i18n = new VueI18n({
  locale,
  messages,
  fallbackLocale: "hu_HU"
});

export default i18n;
