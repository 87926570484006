import api from "@/api/auth";
import store from "@/store/index";

const authLogin = (context, data) =>
  new Promise((resolve, reject) => {
    api
      .authLogin(data)
      .then(res => {
        context.commit("SET_USER_INFO", res);
        resolve(res.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const authLogout = () =>
  new Promise((resolve, reject) => {
    api
      .authLogout()
      .then(() => {
        store.commit("auth/REMOVE_USER");
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });

const dropOut = () =>
  new Promise(resolve => {
    store.commit("auth/REMOVE_USER");
    resolve();
  });

export default {
  authLogin,
  authLogout,
  dropOut
};
