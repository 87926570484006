import { fetchData, apiURL } from "./api_helper";

//  USERS CRUD

//  Search Users
const searchUsers = data =>
  new Promise((resolve, reject) => {
    const endpointURL = `${apiURL}/users/search/${data.page}`;
    fetchData(endpointURL, "get", data.body)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        const data = error.response ? error.response.data : error;
        reject(data);
      });
  });

//  Change Limits
const changeLimits = data =>
  new Promise((resolve, reject) => {
    const endpointURL = `${apiURL}/users/${data.id}/changeLimits`;
    fetchData(endpointURL, "post", data.body)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        const data = error.response ? error.response.data : error;
        reject(data);
      });
  });

export default {
  searchUsers,
  changeLimits
};
