<template>
  <div id="modal-header">
    <div class="title">
      {{ title }}
    </div>
    <div class="close-icon">
      <img
        class="close"
        @click="closeModal"
        src="@/assets/images/icons/close.svg"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalHeader",
  props: {
    title: {
      type: String,
      required: true
    }
  },
  components: {},
  data: () => ({}),
  created() {},
  methods: {
    closeModal() {
      this.$eventBus.closeModal();
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/variables";
#modal-header {
  width: 100%;
  height: fit-content;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 17px;
  }
  .close-icon {
    height: 100%;
    display: flex;
    align-items: center;
    .close {
      height: 19px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(0.8);
      }
    }
  }
}
</style>
