<template>
  <v-app class="app">
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <v-snackbar
          v-model="snackbar"
          :multi-line="
            snackBarComponent.multiLine ? snackBarComponent.multiLine : true
          "
          :timeout="
            snackBarComponent.timeout ? snackBarComponent.timeout : 3500
          "
          :color="snackBarComponent.color ? snackBarComponent.color : 'primary'"
          right
          top
        >
          {{ snackBarComponent.text }}

          <!-- <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template> -->
        </v-snackbar>

        <v-dialog
          v-model="showDialog"
          :width="modalComponent ? modalComponent.bind.width : undefined"
          maxWidth="90vw"
          maxHeight="90vh"
          scrollable
        >
          <v-card v-if="showDialog">
            <modal-header :title="modalComponent.bind.title" />
            <component
              v-bind:is="modalComponent.bind.is"
              v-bind="modalComponent.bind"
              v-on="modalComponent.on"
            />
          </v-card>
        </v-dialog>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  components: {},
  data: () => ({
    showDialog: false,
    modalComponent: undefined,
    snackbar: false,
    snackBarComponent: {}
  }),
  created() {
    this.subscribeEventbusEvents();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    this.unSubscribeEventbusEvents();
  },
  methods: {
    ...mapActions({
      updateWindowWidth: "layout/updateWindowWidth"
    }),
    handleResize() {
      this.updateWindowWidth(window.innerWidth);
    },
    unSubscribeEventbusEvents() {
      this.$eventBus.offShowModal();
      this.$eventBus.offCloseModal();
    },
    subscribeEventbusEvents() {
      this.$eventBus.onShowModal(this, component => {
        this.showDialog = true;
        this.modalComponent = component;
      });
      this.$eventBus.onCloseModal(this, () => {
        this.showDialog = false;
        this.modalComponent = undefined;
      });
      this.$eventBus.onShowSnackBar(this, component => {
        this.snackbar = true;
        this.snackBarComponent = component;
      });
      this.$eventBus.onCloseSnackBar(this, () => {
        this.snackbar = false;
        this.snackBarComponent = undefined;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.app {
  width: 100vw;
  height: 100vh;
}
</style>
