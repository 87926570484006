const getters = {
  getActiveUser: state => {
    return state.activeUser;
  },
  getUserToken: state => {
    return state.activeUser.apiKeys
      ? state.activeUser.apiKeys[0].key
      : undefined;
  }
};

export default getters;
