<template>
  <div id="full-page-layout">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "FullPageLayout",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  methods: {},
  computed: {}
};
</script>

<style lang="scss" scoped>
#full-page-layout {
}
</style>
