import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// Styles: SCSS
import "./assets/style/main.scss";

// GLOBAL COMPONENTS
import "@/assets/js/globalComponents";

//  EventBus for global event handling
import EventBus from "@/eventbus/EventBus";

//  MOMENT JS
import moment from "moment";

//  i18n
import i18n from "./i18n/lang";

Vue.prototype.$eventBus = EventBus;
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
