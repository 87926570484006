<template>
  <div>
    <v-navigation-drawer app v-model="drawer">
      <div class="nav-menu-content-wrapper">
        <navmenu-content />
      </div>
    </v-navigation-drawer>

    <v-app-bar app>
      <div class="nav-bar-content-wrapper">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <transition name="fade">
          <div v-if="!drawer && getWindowBreakPoint !== 'xs'">
            <xeropan-logo class="logo" @click="toHome" />
          </div>
        </transition>
        <div>
          <div class="userName">
            {{ getActiveUser.fullName }}
          </div>
          <div class="logout" @click="logout">
            {{ $t("loggedInLayout.navbar.logout") }}
          </div>
        </div>
      </div>
    </v-app-bar>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  name: "LoggedInLayout",
  data() {
    return {
      drawer: true
    };
  },
  created() {
    this.drawer =
      this.getWindowBreakPoint === "lg" || this.getWindowBreakPoint === "xl";
  },
  computed: {
    ...mapGetters({
      getWindowBreakPoint: "layout/getWindowBreakPoint",
      getActiveUser: "auth/getActiveUser"
    })
  },
  methods: {
    ...mapActions({
      authLogout: "auth/authLogout"
    }),
    toHome() {
      this.this.$router.push("/");
    },
    logout() {
      this.authLogout().then(() => {
        this.$router.push("/login");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-menu-content-wrapper {
  width: 100%;
  height: 100%;
}
.nav-bar-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .logo {
    cursor: pointer;
  }
  .userName {
    font-size: 14px;
  }
  .logout {
    font-size: 12px;
    text-align: right;
    cursor: pointer;
    color: red;
    transition: all 0.3s ease-in-out;
    &:hover {
      font-weight: bold;
    }
  }
}
</style>
