const getters = {
  getWindowBreakPoint: state => {
    if (state.windowWidth >= 1904) return "xl";
    else if (state.windowWidth >= 1264) return "lg";
    else if (state.windowWidth >= 960) return "md";
    else if (state.windowWidth >= 600) return "sm";
    else return "xs";
  },

  getWindowWidth: state => {
    return state.windowWidth;
  }
};

export default getters;
