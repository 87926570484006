import axios from "axios";
import store from "@/store";
// import moment from "moment-timezone";
import { globalErrorHandler } from "@/components/shared/globalErrors";

const apiURL = process.env.VUE_APP_CLASSROOM_URL;
// const apiURL = "https://stage-api.classroom.xeropan.com/admin";

function fetchData(endpoint, requestType, requestBody, authTokenRequired) {
  const secretKey = process.env.VUE_APP_ADMIN_SECRET_KEY;
  const token = store.getters["auth/getUserToken"];
  // const timeZone = moment.tz.guess();
  let config = "";
  // const language = fromLongToShort[i18n.locale];
  const language = "hu";
  if (authTokenRequired !== undefined && authTokenRequired === false) {
    config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "Accept-Language": language,
        "x-admin-api-secret": secretKey

        // "Time-Zone": timeZone
      }
    };
  } else {
    config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "Accept-Language": language,
        "x-admin-api-secret": secretKey,
        "x-api-key": token
        // "Time-Zone": timeZone
      }
    };
  }

  return new Promise((resolve, reject) => {
    if (requestType.toUpperCase() === "POST") {
      return axios
        .post(endpoint, requestBody, config)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          // const data = err.response ? err.response.data : err;
          globalErrorHandler(err);
          reject(err);
        });
    } else if (requestType.toUpperCase() === "GET") {
      if (requestBody) {
        return axios
          .get(endpoint, { headers: config.headers, params: requestBody })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            // const data = err.response ? err.response.data : err;
            globalErrorHandler(err);
            reject(err);
          });
      }
      return axios
        .get(endpoint, config)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          // const data = err.response ? err.response.data : err;
          globalErrorHandler(err);
          reject(err);
        });
    } else if (requestType.toUpperCase() === "PATCH") {
      return axios
        .patch(endpoint, requestBody, config)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          // const data = err.response ? err.response.data : err;
          globalErrorHandler(err);
          reject(err);
        });
    } else if (requestType.toUpperCase() === "DELETE") {
      if (requestBody) {
        return axios
          .delete(endpoint, { headers: config.headers, data: requestBody })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            // const data = err.response ? err.response.data : err;
            globalErrorHandler(err);
            reject(err);
          });
      }
      return axios
        .delete(endpoint, config)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          // const data = err.response ? err.response.data : err;
          globalErrorHandler(err);
          reject(err);
        });
    }
    throw new Error("Unknown HTTP method");
  });

  // if (
  //   requestType.toLowerCase() === "post" ||
  //   requestType.toLowerCase() === "get" ||
  //   requestType.toLowerCase() === "put" ||
  //   requestType.toLowerCase() === "delete" ||
  //   requestType.toLowerCase() === "patch"
  // ) {
  //   const axiosConfig = {
  //     method: requestType,
  //     url: endpoint,
  //     headers: config.headers
  //   };
  //   if (
  //     requestType.toLowerCase() === "get" ||
  //     requestType.toLowerCase() === "delete"
  //   ) {
  //     if (requestBody) {
  //       axiosConfig.params = requestBody;
  //     }
  //     return axios(axiosConfig)
  //       .then(resp => resp)
  //       .catch(err => {
  //         globalErrorHandler(err);
  //       });
  //   } else if (requestType.toLowerCase() === "post") {
  //     axiosConfig.data = requestBody;
  //     return axios(axiosConfig)
  //       .then(response => {
  //         console.log(response);
  //         return response;
  //       })
  //       .catch(err => {
  //         globalErrorHandler(err);
  //       });
  //   } else if (
  //     requestType.toLowerCase() === "patch" ||
  //     requestType.toLowerCase() === "put"
  //   ) {
  //     axiosConfig.data = requestBody;
  //     return axios(axiosConfig)
  //       .then(response => response)
  //       .catch(err => {
  //         globalErrorHandler(err);
  //       });
  //   }
  // } else if (requestType.toLowerCase() === "file") {
  //   const formData = new FormData();
  //   formData.append("file", requestBody);
  //   config.headers["Content-Type"] = "multipart/form-data";
  //   const axiosConfig = {
  //     method: "post",
  //     url: endpoint,
  //     headers: config.headers
  //   };
  //   axiosConfig.data = formData;
  //   return axios(axiosConfig)
  //     .then(response => response)
  //     .catch(err => {
  //       globalErrorHandler(err);
  //     });
  // }
  // throw new Error("Unknown HTTP method");
}

export { fetchData, apiURL };
