<template>
  <div id="teachers-edit-modal">
    <div class="first-wrapper">
      <v-text-field
        min="1"
        ref="firstInput"
        type="number"
        @blur="checkStudentLimit"
        v-model="studentLimit"
        :label="$t('teachersScreen.limitationChangeModal.studentLimit')"
      ></v-text-field>
    </div>
    <div class="second-wrapper">
      <v-text-field
        min="1"
        type="number"
        @blur="checkClassLimit"
        v-model="classLimit"
        :label="$t('teachersScreen.limitationChangeModal.classLimit')"
      ></v-text-field>
    </div>
    <div class="third-wrapper">
      <v-checkbox
        v-model="showProLabel"
        :label="$t('teachersScreen.limitationChangeModal.showPro')"
      ></v-checkbox>
    </div>
    <div class="button-wrapper">
      <v-btn depressed @click="closeModal" :loading="isSaving">
        {{ $t("teachersScreen.limitationChangeModal.cancelButton.text") }}
      </v-btn>
      <v-btn depressed @click="updateLimit" color="primary" :loading="isSaving">
        {{ $t("teachersScreen.limitationChangeModal.saveButton.text") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TeachersEditModal",
  props: {
    teacher: {
      type: Object,
      required: true
    }
  },
  components: {},
  data: () => ({
    isSaving: false,
    studentLimit: undefined,
    classLimit: undefined,
    showProLabel: undefined
  }),
  created() {
    this.studentLimit = this.teacher.limitations.students;
    this.classLimit = this.teacher.limitations.classes;
    this.showProLabel = this.teacher.limitations.proVisibility;
    document.addEventListener("keyup", this.handleKeyUp);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    ...mapActions({
      changeLimits: "users/changeLimits"
    }),
    checkClassLimit() {
      if (Number(this.classLimit) < 1) {
        this.classLimit = 1;
      }
      this.classLimit = Math.abs(Math.round(Number(this.classLimit)));
    },
    checkStudentLimit() {
      if (Number(this.studentLimit) < 1) {
        this.studentLimit = 1;
      }
      this.studentLimit = Math.abs(Math.round(Number(this.studentLimit)));
    },
    handleKeyUp(evt) {
      if (evt.key === "Enter") {
        this.updateLimit();
      }
    },
    updateLimit() {
      this.checkClassLimit();
      this.checkStudentLimit();
      this.isSaving = true;
      const body = {
        limitations: {
          students: this.studentLimit,
          classes: this.classLimit,
          proVisibility: this.showProLabel
        }
      };
      this.changeLimits({ id: this.teacher._id, body }).then(() => {
        this.$emit("refreshScreen");
        this.$eventBus.closeModal();
        this.isSaving = false;
      });
    },
    closeModal() {
      this.$eventBus.closeModal();
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
#teachers-edit-modal {
  padding: 25px;
  padding-top: 10px;
  width: 500px;
  max-width: 100%;
  max-height: 100%;
  .first-wrapper {
    margin-top: 10px;
  }
  .second-wrapper {
    margin-top: 10px;
  }
  .third-wrapper {
    width: max-content;
    margin-top: 10px;
  }
  .button-wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
