var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"teachers"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('div',{staticClass:"card-data"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("teachersScreen.table.filter.title")))]),_c('div',{staticClass:"filter"},[_c('v-text-field',{attrs:{"label":_vm.$t('teachersScreen.table.filter.input')},model:{value:(_vm.filterWord),callback:function ($$v) {_vm.filterWord=$$v},expression:"filterWord"}})],1),_c('div',{staticClass:"button-wrapper"},[_c('v-btn',{staticClass:"save-button",staticStyle:{"margin":"auto"},attrs:{"depressed":"","color":"primary"},on:{"click":_vm.searchByWord}},[_vm._v(" "+_vm._s(_vm.$t("teacherScreen.filter.searchButton.text"))+" ")])],1)])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"page":_vm.options.page,"options":_vm.options,"server-items-length":_vm.totalUsers,"loading":_vm.loading,"footer-props":{
      itemsPerPageOptions: [5, 10, 20]
    }},on:{"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.profileImageUrl",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{staticStyle:{"margin":"10px 0"},attrs:{"color":"primary","size":"60"}},[_c('img',{attrs:{"width":"60px","src":item.profileImageUrl}})])]}},{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.email ? item.email : "-"))])]}},{key:"item.registrationDate",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.createdAt).format("YYYY-MM-DD H:m:s")))])]}},{key:"item.students",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s([item.currentStudentsCount, item.limitations.students].join("/")))])]}},{key:"item.classes",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s([item.currentClassesCount, item.limitations.classes].join("/")))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editRow(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }