<template>
  <div id="teachers">
    <v-card elevation="2">
      <div class="card-data">
        <div class="title">{{ $t("teachersScreen.table.filter.title") }}</div>
        <div class="filter">
          <v-text-field
            v-model="filterWord"
            :label="$t('teachersScreen.table.filter.input')"
          ></v-text-field>
        </div>
        <div class="button-wrapper">
          <v-btn
            style="margin: auto"
            depressed
            class="save-button"
            color="primary"
            @click="searchByWord"
          >
            {{ $t("teacherScreen.filter.searchButton.text") }}
          </v-btn>
        </div>
      </div>
    </v-card>

    <v-data-table
      :headers="headers"
      :items="users"
      :page.sync="options.page"
      :options.sync="options"
      :server-items-length="totalUsers"
      :loading="loading"
      class="elevation-1"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20]
      }"
    >
      <template v-slot:[`item.profileImageUrl`]="{ item }">
        <v-avatar color="primary" size="60" style="margin: 10px 0">
          <img width="60px" :src="item.profileImageUrl" />
        </v-avatar>
      </template>
      <template v-slot:[`item.email`]="{ item }">
        <span>{{ item.email ? item.email : "-" }}</span>
      </template>
      <template v-slot:[`item.registrationDate`]="{ item }">
        <span>{{ $moment(item.createdAt).format("YYYY-MM-DD H:m:s") }}</span>
      </template>
      <template v-slot:[`item.students`]="{ item }">
        <span>{{
          [item.currentStudentsCount, item.limitations.students].join("/")
        }}</span>
      </template>
      <template v-slot:[`item.classes`]="{ item }">
        <span>{{
          [item.currentClassesCount, item.limitations.classes].join("/")
        }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="editRow(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TeachersEditModal from "@/components/modules/teachers/TeachersEditModal";
import { mapActions } from "vuex";
export default {
  name: "TeachersScreen",
  data() {
    return {
      filterWord: undefined,
      totalUsers: 0,
      users: [],
      loading: true,
      options: {},
      headers: [
        {
          text: this.$t("teachersScreen.table.header.profileImg"),
          align: "start",
          sortable: false,
          value: "profileImageUrl"
        },
        {
          text: this.$t("teachersScreen.table.header.name"),
          value: "fullName"
        },
        { text: this.$t("teachersScreen.table.header.email"), value: "email" },
        {
          text: this.$t("teachersScreen.table.header.registration"),
          value: "registrationDate"
        },
        {
          text: this.$t("teachersScreen.table.header.students"),
          sortable: false,
          value: "students"
        },
        {
          text: this.$t("teachersScreen.table.header.classes"),
          sortable: false,
          value: "classes"
        },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  mounted() {
    window.addEventListener("keyup", this.handleKeyUp);
    this.initScreen();
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  watch: {
    options: {
      handler() {
        this.initScreen();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      searchUsers: "users/searchUsers"
    }),
    handleKeyUp(evt) {
      if (evt.key === "Enter") {
        this.searchByWord();
      }
    },
    searchByWord() {
      this.options.page = 1;
      this.initScreen();
    },
    initScreen() {
      this.getDataFromApi().then(data => {
        this.users = data.items;
        this.totalUsers = data.total;
      });
    },
    editRow(item) {
      this.$eventBus.showModal({
        bind: {
          is: TeachersEditModal,
          title: this.$t("teachersScreen.limitationChangeModal.header.title"),
          teacher: item,
          width: "450px"
        },
        on: {
          refreshScreen: () => {
            this.initScreen();
          }
        }
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise(resolve => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const data = {
          page: page,
          body: {
            term: this.filterWord,
            limit: itemsPerPage,
            direction: sortDesc[0] ? "desc" : "asc",
            order: sortBy[0]
          }
        };
        this.searchUsers(data).then(res => {
          this.loading = false;
          resolve({
            items: res.result,
            total: res.total
          });
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
#teachers {
  padding: 20px;
  .card-data {
    margin-bottom: 15px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    .filter {
      width: 70%;
    }
    .button-wrapper {
      display: flex;
      align-content: center;
    }
  }
}
@media (max-width: 500px) {
  .card-data {
    flex-direction: column;
    .filter {
      margin-top: 10px !important;
      width: 100% !important;
    }
    .button-wrapper {
      justify-content: flex-end !important;
    }
  }
}
</style>
