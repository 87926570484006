const SET_USER_INFO = (state, payload) => {
  state.activeUser = payload;
  localStorage.setItem("activeUser", JSON.stringify(payload));
};

const REMOVE_USER = state => {
  state.activeUser = {};
  localStorage.removeItem("activeUser");
};

export default {
  SET_USER_INFO,
  REMOVE_USER
};
