<template>
  <div id="login-screen">
    <div class="xeropan-logo">
      <xeropan-logo />
    </div>

    <div class="login-card-wrapper">
      <div class="card-title">
        {{ $t("loginScreen.loginForm.title") }}
      </div>
      <v-card elevation="2" class="login-card">
        <v-form ref="form" class="form">
          <v-text-field
            class="email"
            v-model="email"
            :label="$t('loginScreen.loginForm.email')"
            :placeholder="$t('loginScreen.loginForm.email')"
            required
          ></v-text-field>

          <v-text-field
            class="password"
            type="password"
            v-model="password"
            :label="$t('loginScreen.loginForm.password')"
            :placeholder="$t('loginScreen.loginForm.password')"
            required
          ></v-text-field>
          <div style="width: 100%" class="d-flex align-end flex-column">
            <v-btn
              depressed
              :disabled="isLogingIn || !buttonIsValid"
              class="save-button"
              color="primary"
              @click="login"
            >
              {{ $t("loginScreen.loginForm.loginButton.text") }}
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "LoginScreen",
  props: {},
  components: {},
  data: () => ({
    isLogingIn: false,
    email: undefined,
    password: undefined
  }),
  created() {
    window.addEventListener("keyup", this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  computed: {
    buttonIsValid() {
      return (
        this.email &&
        this.email.length > 0 &&
        this.password &&
        this.password.length > 0
      );
    }
  },
  methods: {
    ...mapActions({
      authLogin: "auth/authLogin"
    }),
    handleKeyUp(evt) {
      if (evt.key === "Enter") {
        this.login();
      }
    },
    login() {
      const body = {
        email: this.email,
        password: this.password
      };
      this.authLogin(body).then(() => {
        this.$router.push("/");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#login-screen {
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
  .xeropan-logo {
    position: absolute;
    top: 30px;
    left: 30px;
  }

  .login-card-wrapper {
    position: relative;
    .card-title {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: -30px;
      position: inherit;
      width: 300px;
      max-width: 65vw;
      height: 60px;
      border-radius: 8px;
      color: white;
      font-size: 22px;
      background: #48b0f7;
      border: 2px solid #0095fb;
      box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.2);
    }
    .login-card {
      padding: 35px 20px;
      width: 600px;
      max-width: 90vw;
      height: 300px;
      max-height: 90vh;
      .form {
        .email {
          margin-top: 20px;
        }
        .password {
          margin-top: 20px;
        }
        .save-button {
          margin-top: 20px;
          width: 250px;
          height: 35px;
        }
      }
    }
  }
}
</style>
