<template>
  <div id="xeropan-logo">
    <img draggable="false" src="@/assets/images/xeropan/xeropan-logo.svg" />
    <span class="logo-title">
      Xeropan
      <span class="classroom-title"> Classroom</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "XeropanLogo",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  methods: {},
  computed: {}
};
</script>

<style lang="scss" scoped>
#xeropan-logo {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  img {
    border-style: none;
    vertical-align: middle;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    overflow: hidden;
  }
  .logo-title {
    line-height: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    text-align: right;
    width: 100px;
    font-family: Baloo, cursive !important;
    font-size: 22px;
    color: #15223f;
    font-weight: 400;
    padding: 0 0 0 15px;
    .classroom-title {
      color: #48b0f7;
      display: block;
      font-size: 15px;
      width: 100%;
      font-family: Baloo, cursive !important;
    }
  }
}
</style>
