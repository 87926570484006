import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";

Vue.use(Vuex);

import layout from "./modules/layout";
import auth from "./modules/auth";
import users from "./modules/users";

export default new Vuex.Store({
  modules: {
    layout,
    auth,
    users
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: [
    persistedState({
      key: "store",
      paths: ["auth.activeUser"]
    })
  ]
});
