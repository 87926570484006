import Vue from "vue";

const EventBus = new Vue();

const events = ["ShowModal", "CloseModal", "ShowSnackBar", "CloseSnackBar"];

events.forEach(eventName => {
  EventBus[`on${eventName}`] = (component, callback) => {
    EventBus.$on(eventName, callback);
  };
  EventBus[`off${eventName}`] = (component, callback) => {
    EventBus.$off(eventName, callback);
  };
  EventBus[
    eventName.charAt(0).toLowerCase() + eventName.slice(1)
  ] = payload => {
    EventBus.$emit(eventName, payload);
  };
});

export default EventBus;
