<template>
  <div id="navbar-content">
    <div class="logo-wrapper">
      <xeropan-logo class="logo" @click="toHome" />
    </div>
    <div class="menu-item-wrapper">
      <router-link
        v-for="(item, index) in menuItems"
        :key="index"
        tag="span"
        class="menu-item"
        :to="item.path"
        :active-class="'active-link'"
        exact
      >
        <v-icon class="menu-icon"> {{ item.icon }} </v-icon>
        <p class="menu-component-tilte">
          {{ $t(item.name) }}
        </p>
      </router-link>
    </div>
  </div>
</template>
<script>
import navMenuItems from "@/assets/js/navMenuItems.js";

export default {
  name: "NavbarContent",
  data() {
    return {
      menuItems: navMenuItems
    };
  },
  methods: {
    toHome() {
      this.$router.push("/");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/variables.scss";
#navbar-content {
  height: 100%;
  .logo-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    margin-bottom: 30px;
    .logo {
      transform: scale(1.4);
    }
  }
  .menu-item-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 10px;
    padding-right: 20px;
    .menu-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;
      min-height: 40px;
      padding-left: 10px;
      cursor: pointer;
      border-radius: 8px;
      .menu-icon {
        //   height: 16px;
        //   width: 16px;
        //   color: $color-primary;
        margin-right: 10px;
      }
      .menu-component-tilte {
        font-style: italic;
        font-size: 18px;
        line-height: 20px;
        margin: 0 !important;
        background-color: transparent;
      }
      &:hover {
        background: hsla(0, 0%, 78.4%, 0.2);
      }
    }
    .active-link {
      color: white;
      background-color: $color-primary;
      box-shadow: 0 12px 20px -10px rgba($color-primary, 0.4),
        0 4px 20px 0 rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba($color-primary, 0.2);
      &:hover {
        background: $color-primary;
      }
      .menu-icon {
        color: #fff !important;
        caret-color: #fff !important;
      }
    }
  }
}
</style>
