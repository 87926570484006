import { fetchData, apiURL } from "./api_helper";

//  Auth CRUD

//  LOGIN
const authLogin = data =>
  new Promise((resolve, reject) => {
    const endpointURL = `${apiURL}/adminUsers/login`;
    fetchData(endpointURL, "post", data, false)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        const data = error.response ? error.response.data : error;
        reject(data);
      });
  });

//  LOGOUT
const authLogout = () =>
  new Promise((resolve, reject) => {
    const endpointURL = `${apiURL}/adminUsers/logout`;
    fetchData(endpointURL, "post")
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        const data = error.response ? error.response.data : error;
        reject(data);
      });
  });

export default {
  authLogin,
  authLogout
};
