import EventBus from "@/eventbus/EventBus";
import i18n from "@/i18n/lang";
import actions from "@/store/modules/auth/actions";

export const globalErrorTypes = ["EMAIL_OR_PASSWORD_NOT_MATCH"];

const globalErrorTexts = {
  EMAIL_OR_PASSWORD_NOT_MATCH: "generalErrors.EMAIL_OR_PASSWORD_NOT_MATCH",
  INVALID_TOKEN: "generalErrors.INVALID_TOKEN"
};

export const globalErrorHandler = error => {
  if (globalErrorTypes.includes(error.response.data.type)) {
    EventBus.closeModal();
    EventBus.showSnackBar({
      color: "error",
      text: globalErrorTexts[error.response.data.type]
        ? i18n.t(globalErrorTexts[error.response.data.type])
        : error.response.data.type
    });
  } else if (error.response.data.type === "INVALID_TOKEN") {
    actions.dropOut();
    EventBus.showSnackBar({
      color: "error",
      text: globalErrorTexts[error.response.data.type]
        ? i18n.t(globalErrorTexts[error.response.data.type])
        : error.response.data.type,
      multiLine: false
    });
    setTimeout(() => {
      location.reload();
    }, 1000);
  }
  return Promise.reject(error);
};
